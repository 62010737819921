.popoverHeader {
  background-color: aquamarine;
  color: white;
}
.popoverHeaderEmpty {
  background-color: red;
  color: white;
}
.switchStyle {
  width: "80px";
}
.notemptyPop {
  background-color: green;
}
