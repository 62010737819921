
body{
    font-family: 'Lato', sans-serif;
}  
.ant-menu-dark.ant-menu-horizontal{
    float: right !important;
    min-width: 400px !important;
    max-width: 400px !important;
    
}
.goback{
    cursor: pointer;
}
.goback :hover{
    color: black;
}
.float-right{
    float: right;
}
.pointer:hover{
    cursor: pointer;
}

.hidden{
    height: 1px;
    max-height: 1px;
    display: none;
    visibility: hidden;
}
.jodit-container:not(.jodit_inline) .jodit-wysiwyg {
    background-color: #fff !important;
}

.my-list{
    cursor: grab;
    background: #fafafa;
    border-bottom: #eaeaea 1px solid;
}

.bold{
    font-size: 16px;
    font-weight: bold;
}

.italic{
    font-size: 15px;
    font-style: italic;
    text-decoration: underline;
}

.margin-left-small{
    margin-left: 25px;
}
.margin-left-large{
    margin-left: 50px;
}


.padding-left-small{
    padding-left: 25px;
}


.text-left{
    text-align: left;
}