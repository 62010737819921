.space-align-block {
  flex: none;
  margin: 8px 4px;
  padding: 4px;
  border: 1px solid #40a9ff;
}
.cardSigin {
  margin-top: 120px;
  padding-top: 50px;
  width: 450px;
  height: 450px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.divider {
  font-family: sans-serif;
}
.pass {
  margin-top: 50px;
}
.back{
  background-size:90%;
  background-image: linear-gradient(rgba(0, 0, 255, 0.5), rgba(43, 45, 47, 0.5)),
                  url("../../assets/images/Logo DND Serv.png");
}
.divv {
  /* background-size:100%;
  background-image: linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5)),
                  url("../../assets/images/Logo DND Serv.png"); */
  min-height: 93vh;
}
.buttonSignIn {
  margin-top: 20px;
  background-color: #1890ff;
  width: 100%;
  color: white;
  /* back #1890ff,
  width: "100%",
  color: "white", */
}
.backGroundPic {
  height: 70%;
  width: 70%;
opacity: 0.7;  
}
/* .cardSigin {
  display: flex;
  place-content: flex-end;

  border-radius: 15px;
} */
.raduis {
  padding: 100px;
  border-radius: 50px;
}
.sizing {
  width: 300px;
}
.layoutSigin {
  margin: 500px;
}
